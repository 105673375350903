import { isServer } from 'solid-js/web';
import { useAnalytics } from './context';
import type PostHog from 'posthog-js';

export function useTrackEvent(): Track {
	const ctx = useAnalytics();
	if (!ctx) {
		return noop;
	}

	return (event: AnalyticsEvent, properties?: Record<string, unknown>) => {
		try {
			if (!isServer) {
				window.dataLayer?.push({
					event,
					...properties,
				});
			}
			ctx!.capture(event, properties);
			if (event === 'logOut') {
				// Reset the user to the analytics platform
				// https://posthog.com/docs/libraries/js
				ctx!.reset();
			}
		} catch {
			// ignore
		}
	};
}

const noop = () => undefined;

export function useCapture(): (typeof PostHog)['capture'] {
	const ctx = useAnalytics();
	if (!ctx) {
		return noop;
	}

	return ctx.capture.bind(ctx.capture);
}

export type AnalyticsEvent =
	// Auth
	| 'login'
	| 'logOut'
	| 'signup'
	| 'emailMagicLink'
	| 'confirmEmailMagicLink'
	// Password reset
	| 'passwordResetCode'
	| 'passwordReset'
	// Tee times
	| 'getTeeTimes'
	| 'reserveTeeTime'
	| 'createTeeTimeAlert'
	| 'updateTeeTimeAlert'
	| 'deleteTeeTimeAlert'
	// Reservation Actions
	| 'attemptMobileCheckIn'
	| 'completeMobileCheckIn'
	// Inviting Users
	| 'inviteShare'
	| 'inviteUser'
	| 'removeUser'
	// Invite Actions
	| 'acceptInvitation'
	| 'rejectInvitation'
	// Reservation Actions
	| 'leaveReservation'
	| 'cancelReservation'
	// Check in
	| 'checkIn'
	// Credit cards
	| 'getCreditCardToken'
	| 'storeCreditCard'
	| 'addStoredCreditCard'
	// misc
	| 'getSupport'
	| 'formSubmission'
	| 'claimBenefit'
	// Stripe
	| 'checkout'
	| 'applyPromoCode'
	| 'cancelSubscription'
	// Dialogs
	| 'dialogOpened'
	| 'dialogClosed'
	// Sessions Events
	| 'sessionStart'
	| 'sessionEnd'
	// SignUp
	| 'signupEnteredEmail'
	| 'signupEnteredPassword'
	| 'signupEnteredName'
	| 'signupEnteredZipcode'
	// Screen Events
	| 'screenAppear'
	| 'screenDisappear'
	// Teetime Actions
	| 'changeTeeTimeDay'
	| 'didSelectTeeTime'
	| 'addToCalendar'
	// Push Actions
	| 'registerForPushAccess'
	| 'pushAccess'
	| 'receivedPushNotification'
	| 'tappedPushNotification'
	// Deeplinks
	| 'tappedDeeplink'
	// Payments
	| 'changePaymentType'
	| 'paymentInfoComplete'
	// Misc
	| 'download'
	| 'redeemBenefit'
	// Products
	| 'upsellVisible'
	| 'upsellClicked'
	| 'didSelectAccessProduct';

export type Track = (event: AnalyticsEvent, properties?: Record<string, unknown>) => void;
